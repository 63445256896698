import {
  Component,
  OnInit,
  Input,
  AfterContentChecked,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  LOCALE_ID,
} from '@angular/core';
import { Anagrafica } from 'src/app/_shared/models/anagrafica';
import { EntiService } from 'src/app/_shared/services/enti.service';
import {
  Ente,
  Applicazione,
  CausaleDto,
} from 'src/app/_shared/models/ente';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';
import { FormComponent } from 'src/app/_shared/form-component';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Utility } from 'src/app/_shared/utility';
import { SipaPlusStorageService } from 'src/app/_shared/sipaplus-storage.service';
import { PaganteStorageService } from 'src/app/_shared/models/pagante-storage-service';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-info-pagamento-plus',
  templateUrl: './info-pagamento-plus.component.html',
  styleUrls: ['./info-pagamento-plus.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class InfoPagamentoPlusComponent extends FormComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() anagrafica: Anagrafica;
  @Output() sePlus = new EventEmitter();
  @Output() servizio = new EventEmitter();
  @Output() controlloSeVuotoPagamentoSpontaneoEE = new EventEmitter();//inviamo al padre (anagrafica) che servira' per visualizzare il pop up
  @Output() controlloSeVuotoImportoEE = new EventEmitter();
  @Output() controlloCinqueServizi = new EventEmitter();
  @Output() inviaSePlus = new EventEmitter();
  @Input() invioSelezionati;
  @Output() controlloAliquotaMaggioreZero = new EventEmitter(); //A.A. se i campi aliquotaIva sono maggiori di 0 apro anagrafica-persona

  hasEntiSubscription: Subscription;
  choices: any[] = [];
  visualizzaGrigliaCausali: boolean;
  applicazioneSelezionata: any;
  formAffitti: FormGroup;
  totaleAltro: any;
  tot: number = 0;
  plus: string;
  controlloSeVuotoImporto: boolean;

  ngAfterContentChecked(): void {
    if(this.translate.currentLang=='it'){
      this.anagrafica.enteDesc = this.entiEnabled[0].denominazioneEnte.split("#")[0].split(":")[1] != null
      ? this.entiEnabled[0].denominazioneEnte.split("#")[0].split(":")[1]
      : this.entiEnabled[0].denominazioneEnte
    }
    if(this.translate.currentLang=='de'){
      this.anagrafica.enteDesc = this.entiEnabled[0].denominazioneEnte.split("#")[1].split(":")[1] != null
      ? this.entiEnabled[0].denominazioneEnte.split("#")[1].split(":")[1]
      : this.entiEnabled[0].denominazioneEnte
    }
   }

  @Input() nascondiRighe = false;

  constructor(
    private entiSrv: EntiService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private sipaPlusStorageService: SipaPlusStorageService,
    private storageService: PaganteStorageService
    ) {
    super();
    this.formAffitti = this.formBuilder.group({
      listaDett: this.formBuilder.array([], [Validators.required]),
    });
  }
  checkIntestazione: boolean = true;
  checkValore: boolean = true;
  mostraTabella: boolean = true;

  faInfoCircle = faInfoCircle;

  //A.A.
  isCheckboxSelected: { [key: string]: boolean } = {};
  
  ngOnInit() {
    this.visualizzazioniSeSipaPlus();
    this.mostraTabella = true;
    this.controlloSeVuotoImporto = true;
    // this.storageService.setErrorePagamentoSpontaneo("true");

    this.storageService.setTotale("true");
    this.InputValue;

    if (!this.isDetail) {
      this.hasEntiSubscription = this.entiSrv.hasEnti$.subscribe((status) => {
        if (status) {
          this.anagrafica.codiceEnte = this.entiEnabled[0].codiceEnte;
          if(this.translate.currentLang=='it'){
            this.anagrafica.enteDesc = this.entiEnabled[0].denominazioneEnte.split("#")[0].split(":")[1] != null
            ? this.entiEnabled[0].denominazioneEnte.split("#")[0].split(":")[1]
            : this.entiEnabled[0].denominazioneEnte
          }
          if(this.translate.currentLang=='de'){
            this.anagrafica.enteDesc = this.entiEnabled[0].denominazioneEnte.split("#")[1].split(":")[1] != null
            ? this.entiEnabled[0].denominazioneEnte.split("#")[1].split(":")[1]
            : this.entiEnabled[0].denominazioneEnte
          }
          //this.anagrafica.enteDesc = this.entiEnabled[0].denominazioneEnte;
          
        }
      });
    }

    //caso pulsante indietro====================================================
    let applicazioneSelezionata = this.storageService.getApplicazione();
    let choisesBackStorage = this.storageService.getChoisesBack();
    if (applicazioneSelezionata != undefined && choisesBackStorage != null) {
      this.onSelectApplicazione(+applicazioneSelezionata);
    }
    //FINE caso pulsante indietr===============================================

    
    if (this.invioSelezionati != undefined) {
      this.mostraTabella = false;
      this.anagrafica.sipaPlus = this.sipaPlusStorageService.getSipaPlus();

      this.checkIntestazione = false;
      this.checkValore = false;
      let totale = 0;
      this.choices = this.invioSelezionati;
      this.storageService.setChoisesBack(this.choices);
      this.choices.forEach((element) => {
        let storedNames = localStorage.getItem('quantita' + element.idCausale);
        //caso di ALTRO
        if (this.ritornaStatoPagamentoSpontaneo(element.idCausale) == '1') {
          let totaleAltro = localStorage.getItem('totaleAltro' + element.idCausale);
          element.totale = totaleAltro;
          let totaleNumber: number = +element.totale; // converto in number
          totaleNumber.toFixed(2);
          totale * 1;
          totale = totale + totaleNumber;
        } else {
          // caso normale
          element.quantita = storedNames;
          element.totale = this.calcoloTotale(
            element.importo,
            element.aliquota,
            storedNames
          );
          let str = (+element.totale).toFixed(2);
          let y: number = +str;
          element.totale = y;
          totale * 1;
          totale = totale + element.totale;

          this.importoTotale = element.totale;
        }
      });
      this.importoTotale = totale;
    }
  }

  get entiEnabled(): Ente[] {
    return this.entiSrv.entiInMemory;
  }

  get applicazioniEnabled(): Applicazione[] {
    const enteSelected = this.entiEnabled?.find(
      (x) => x.codiceEnte === this.anagrafica.codiceEnte
    );
    return enteSelected?.elencoApplicazioni ?? [];
  }

  get isUserST(): boolean {
    return this.entiSrv.isUserST;
  }

  ngOnDestroy(): void {
    if (!this.isDetail) {
      this.hasEntiSubscription.unsubscribe();
    }
  }

 
  // al click in altro sulla parte decimale viene cancellato
  svuota(e, id) {
    this.choices.forEach((element) => {
      if (element.idCausale == id && this.ritornaStatoPagamentoSpontaneo(element.idCausale) == '1') {
        (<HTMLInputElement>(document.getElementById('inputTotaleDecimal-' + element.idCausale))).value = '';
      }
    });
  }

  pointToComma(n: number): string {
    let value = n.toString();
    value = value.replace(/\./g, ',');
    return value;
  }
  commaToPoint(n: number): string {
    let value = n.toString();
    value = value.replace(/,/g, '.');
    return value;
  }
  commaToPointString(n: string): string {
    // let value = n.toString();
    n = n.replace(/,/g, '.');
    return n;
  }

  // ====================================================================================
  //selezionati: false;

  choicesSelezionati: any[] = [];

  saveStorage(listaIdSelezionati, quantita) {
    var dett = [];
    dett[0] = this.choices;
    localStorage.setItem('choices', JSON.stringify(dett));

    var listId = [];
    listId[0] = listaIdSelezionati;
    localStorage.setItem('listId', JSON.stringify(listId));

    var storedNames = JSON.parse(localStorage.getItem('choices'));
    var storedNames = JSON.parse(localStorage.getItem('listId'));

    for (let i of listaIdSelezionati) {
      this.choices.forEach((element) => {
        if (i == element.idCausale) {
          this.choicesSelezionati.push(element);
        }
      });
    }
    localStorage.setItem('choicesSelezionati', JSON.stringify(this.choicesSelezionati));
  }

  // =======================

  visualizzazioniSeSipaPlus() {
    if (!this.isDetail) {
      this.hasEntiSubscription = this.entiSrv.hasEnti$.subscribe((status) => {
        if (status) {
          this.plus = this.sipaPlusStorageService.getSipaPlus();
          this.sePlus.emit(this.plus);

          if (this.plus === '0') {
            this.visualizzaGrigliaCausali = false;
            this.inviaSePlus.emit('0');
          } else if (this.plus === '1') {
            this.visualizzaGrigliaCausali = true;
            this.inviaSePlus.emit('1');
          }
        }
      });
    }
  }

  @Output() nascondiRigheReturn = new EventEmitter();
  InputValue: any;

  onSelectApplicazione(value): void {
    // svuota i dettagli selezionati dalla lista della checkbox
    // la listaDett contiene l'elenco degli id causale selezionali
    let listaDett: FormArray = this.formAffitti.get('listaDett') as FormArray;
    listaDett.clear();
    localStorage.removeItem("choicesSelezionati");
    // serve per settare a zero l'importo totale quando cambio servizio
    this.importoTotale = 0;
    this.anagrafica.idServizio = value;
    this.applicazioneSelezionata = this.applicazioniEnabled.find(
      (app) => app.idApplicazione == this.anagrafica.idServizio
    );

    //A.A. se cambio servizio dove non c'e aliquota Iva chiudo anagrafica-persona 
    this.controlloAliquotaMaggioreZero.emit(false); 


    this.storageService.setApplicazione(value);//salvo nello storage l'applicazione selezionata utile per il tasto indietro

    const { listaCausali } = this.applicazioneSelezionata; // per accedere ai dettagli delle causali

    if (listaCausali == undefined) {
      this.nascondiRighe = false;  // quando viene premuto reset non mostrare più le righe
    }
    else {
      this.nascondiRigheReturn.emit(true); // quando viene premuto reset per una seconda volta rimandare al padre true altrimenti dfunziona solo una volta
    }

    if (this.choices.length > 0) {
      this.choices = []; // svuoto l'array dei dettagli
    }

    listaCausali.forEach((element) => {
  //    console.log(JSON.stringify(element));
      if (element.fSpontaneo == "1") {
        element.importo = "";
        element.totale = "";
        element.totaleFix = "";
      }
      if (element.fObbligatorio == "1") {
        element.quantita="1";
//        element.importo = "";
//        element.totale = "";
//        element.totaleFix = "";
        let importoProvv:any =0;
          if(element.aliquota>0){
            importoProvv= element.importo* (100+element.aliquota) / 100;
            element.totale=importoProvv;
            element.totaleFix = importoProvv;
            //A.A. controllo se i campi aliquotaIva sono maggiori di 0 e apro anagrafica-persona  
            this.controlloAliquotaMaggioreZero.emit(true); 
          }else {
            importoProvv= element.importo;
            element.totale=importoProvv
          }
      }  
      this.choices.push(element); // inserisce i servi disponibili in choices
      this.saveStorage(listaDett.value, this.quantita);
      element.totale = ''; // serve per settare a zero l'importo del servizio, quando cambio servizio
      //A.A.
      console.log(this.choices);
    });

    this.anagrafica.cdp = this.applicazioneSelezionata?.cdp;
    this.anagrafica.cpk = this.applicazioneSelezionata?.cpk;
    this.anagrafica.tipoContabilizzazione = this.applicazioneSelezionata?.tipoContabilizzazione;
    //CONTROLLO DEL TOTALE================================================

    if (this.importoTotale == undefined || this.importoTotale == null || this.importoTotale == "" || this.importoTotale == 0) {
      this.controlloSeVuotoImporto = true;
      this.storageService.setTotale("true");
    } else {
      this.controlloSeVuotoImporto = false;
      this.storageService.setTotale("false");
    }
    this.controlloSeVuotoImportoEE.emit(this.controlloSeVuotoImporto);
    //FINE CONTROLLO DEL TOTALE================================================
  }

  calcolaCheckBoxObbligatoria(e){
    setTimeout (() => {
      console.log("Hello from setTimeout");
      const listaDett: FormArray = this.formAffitti.get('listaDett') as FormArray;
      this.choices.forEach((element) => {
        if (element.fObbligatorio == "1") {
          (<HTMLInputElement>(document.getElementById("checkbox-"+ element.idCausale))).checked=true;
          (<HTMLInputElement>(document.getElementById("checkbox-"+ element.idCausale))).disabled=true;
          listaDett.push(new FormControl(element.idCausale));
          this.sommaTotaleRiga(null, element.idCausale);

      // Aggiorna lo stato di selezione della checkbox Aggiungendo l'icona note
      
          
      //listaDett.clear();
  
      this.idSelezionato = element.idCausale;
      this.isCheckboxSelected[this.idSelezionato] = true;
      this.idTotale = 'inputTotale-' + this.idSelezionato;
      this.idTotaleDecimal = 'inputTotaleDecimal-' + this.idSelezionato;
      this.idQuantita = 'inputQuantita-' + this.idSelezionato;
      this.idImportoNetto = 'idimportoNetto-' + this.idSelezionato;
      this.idAliquotaIva = 'idAliquotaIva-' + this.idSelezionato;
      this.idDettaglio = 'idDettaglio-' + this.idSelezionato;
  
      this.descCausale = (<HTMLInputElement>(document.getElementById(this.idDettaglio))).value;
      this.importoNetto = (<HTMLInputElement>(document.getElementById(this.idImportoNetto))).value;
      this.aliquotaIva = (<HTMLInputElement>(document.getElementById(this.idAliquotaIva))).value;
      this.totale = (<HTMLInputElement>(document.getElementById(this.idTotale))).value;
      this.totaleDecimale = (<HTMLInputElement>(document.getElementById(this.idTotaleDecimal))).value;
      this.quantita = (<HTMLInputElement>(document.getElementById(this.idQuantita))).value;
      (<HTMLInputElement>(document.getElementById(this.idQuantita))).disabled=false;
      (<HTMLInputElement>(document.getElementById(this.idQuantita))).focus();  

      //CHECKED
      if (e.target.checked) {
        listaDett.push(new FormControl(e.target.value));
        
        if (this.ritornaStatoPagamentoSpontaneo(e.target.value) == '0') {
          (<HTMLInputElement>document.getElementById(this.idQuantita) as HTMLButtonElement).disabled = false;
          (<HTMLInputElement>document.getElementById(this.idQuantita)).value = '1';
          this.quantita = '1';
          this.totale = this.calcoloTotale(this.importoNetto, this.aliquotaIva, this.quantita);

          if (this.totale.toString().includes('.') || this.totale.toString().includes(',')) {
            (<HTMLInputElement>document.getElementById(this.idTotale)).value = this.fParteIntera(this.totale); //aggiorno parte intera
            (<HTMLInputElement>(document.getElementById(this.idTotaleDecimal))).value = this.fParteDecimale(this.totale); //aggiorno parte decimale
          }
          else {
            (<HTMLInputElement>document.getElementById(this.idTotale)).value = this.totale; //caso in cui non si hanno cifre decimali
            (<HTMLInputElement>(document.getElementById(this.idTotaleDecimal))).value = '00';
          }        
        }
        let dett = this.quantita;
        localStorage.setItem('quantita' + this.idSelezionato, dett);
        this.servizio.emit(listaDett.length.toString()); //invia al padre per controllo massimo 5
        this.sommaTotaleRiga(e, null); // per far si che al check si sommi istantaneamente
      }
      }
      });//END FOR
  
      // SAVE STORAGE
      this.choicesSelezionati = []; //svuoto l'array
      this.saveStorage(listaDett.value, this.quantita);
      //CONTROLLO DEL TOTALE================================================
      if (this.importoTotale == undefined || this.importoTotale == null || this.importoTotale == "" || this.importoTotale == 0) {
        this.controlloSeVuotoImporto = true;
        this.storageService.setTotale("true");
      } else {
        this.controlloSeVuotoImporto = false;
        this.storageService.setTotale("false");
      }
      this.controlloSeVuotoImportoEE.emit(this.controlloSeVuotoImporto);
      //FINE CONTROLLO DEL TOTALE=======
  
   }, 1000);
    
  }

  ritornaStatoPagamentoSpontaneo(id: string): string {
    let pSpontaneo = '0';
    this.applicazioneSelezionata = this.applicazioniEnabled.find(
      (app) => app.idApplicazione == this.anagrafica.idServizio
    );
    const { listaCausali } = this.applicazioneSelezionata;
    listaCausali.forEach((element) => {
      if (element.fSpontaneo == '0' && element.idCausale == id) {
        pSpontaneo = '0';
      } else if (element.fSpontaneo == '1' && element.idCausale == id) {
        pSpontaneo = '1';
      }
    });
    return pSpontaneo;
  }

  calcoloTotale(importoNetto: any, aliquotaIva: any, quantita: any) {
    let totale;
    totale = ((importoNetto * 1) * (aliquotaIva * 1) / 100) + importoNetto * 1;
    totale = totale * 1 * (quantita * 1);
    totale = totale.toFixed(2);
    if (!totale.toString().includes(".")) totale = totale + ".00";
    
    return totale;
  }

  alertMaxCinque: boolean = false;
  idTotale: string = '';
  idQuantita: string = '';
  idSelezionato: string = '';
  idImportoNetto: string = '';
  idAliquotaIva: string = '';
  idDettaglio: string = '';
  importoNetto: any;
  aliquotaIva: any;
  totale: any = '';
  totaleDecimale: any = '';
  quantita: any;
  descCausale: any;
  idTotaleDecimal: any = '';
  
   onCheckboxChange(e) {
    const listaDett: FormArray = this.formAffitti.get('listaDett') as FormArray;
    //listaDett.clear();

    this.idSelezionato = e.target.value;
    this.idTotale = 'inputTotale-' + this.idSelezionato;
    this.idTotaleDecimal = 'inputTotaleDecimal-' + this.idSelezionato;
    this.idQuantita = 'inputQuantita-' + this.idSelezionato;
    this.idImportoNetto = 'idimportoNetto-' + this.idSelezionato;
    this.idAliquotaIva = 'idAliquotaIva-' + this.idSelezionato;
    this.idDettaglio = 'idDettaglio-' + this.idSelezionato;

    this.descCausale = (<HTMLInputElement>(document.getElementById(this.idDettaglio))).value;
    this.importoNetto = (<HTMLInputElement>(document.getElementById(this.idImportoNetto))).value;
    this.aliquotaIva = (<HTMLInputElement>(document.getElementById(this.idAliquotaIva))).value;
    this.totale = (<HTMLInputElement>(document.getElementById(this.idTotale))).value;
    this.totaleDecimale = (<HTMLInputElement>(document.getElementById(this.idTotaleDecimal))).value;
    this.quantita = (<HTMLInputElement>(document.getElementById(this.idQuantita))).value;
   

    //CHECKED
    if (e.target.checked) {
      listaDett.push(new FormControl(e.target.value));

      // Aggiorna lo stato di selezione della checkbox Aggiungendo l'icona note
      this.isCheckboxSelected[this.idSelezionato] = true;

      if (this.ritornaStatoPagamentoSpontaneo(e.target.value) == '0') {
        (<HTMLInputElement>document.getElementById(this.idQuantita) as HTMLButtonElement).disabled = false;
        (<HTMLInputElement>document.getElementById(this.idQuantita)).value = '1';
        this.quantita = '1';
        this.totale = this.calcoloTotale(this.importoNetto, this.aliquotaIva, this.quantita);
        if(this.aliquotaIva>0) {
          this.controlloAliquotaMaggioreZero.emit(true);
          }

        if (this.totale.toString().includes('.') || this.totale.toString().includes(',')) {
          (<HTMLInputElement>document.getElementById(this.idTotale)).value = this.fParteIntera(this.totale); //aggiorno parte intera
          (<HTMLInputElement>(document.getElementById(this.idTotaleDecimal))).value = this.fParteDecimale(this.totale); //aggiorno parte decimale
        }
        else {
          (<HTMLInputElement>document.getElementById(this.idTotale)).value = this.totale; //caso in cui non si hanno cifre decimali
          (<HTMLInputElement>(document.getElementById(this.idTotaleDecimal))).value = '00';
        }
      }
      else {//SPONTANEO
        this.controlloSeVuotoPagamentoSpontaneoEE.emit(true);
        this.storageService.setErrorePagamentoSpontaneo("true");
        (<HTMLInputElement>document.getElementById(this.idQuantita) as HTMLButtonElement).disabled = true;
        (<HTMLInputElement>document.getElementById(this.idQuantita)).value = '';
        this.quantita = '';
        (<HTMLInputElement>document.getElementById(this.idTotale) as HTMLButtonElement).disabled = false; // sblocco il totale riga di altro
        (<HTMLInputElement>document.getElementById(this.idTotaleDecimal) as HTMLButtonElement).disabled = false; // abilita i decimali
        // (<HTMLInputElement>document.getElementById(this.idTotale)).value = '';  // va a mettere nella casella totale riga "altro" vuota
      }

      let dett = this.quantita;
      localStorage.setItem('quantita' + this.idSelezionato, dett);
      this.servizio.emit(listaDett.length.toString()); //invia al padre per controllo massimo 5
      this.sommaTotaleRiga(e, null); // per far si che al check si sommi istantaneamente
      this.choices.forEach((element) => {
        if (element.idCausale == e.target.value) {
          element.quantita = this.quantita;//metto il numeretto
        }
      });//END FOR

    }
    else {//NOT CHECKED
      const index = listaDett.controls.findIndex((x) => x.value === e.target.value);
      listaDett.removeAt(index);

      // Aggiorna lo stato di selezione della checkbox rimuovendo l'icona note
      this.isCheckboxSelected[this.idSelezionato] = false;

      this.descCausale = (<HTMLInputElement>(document.getElementById(this.idDettaglio))).value;

      if (this.ritornaStatoPagamentoSpontaneo(e.target.value) == '0') {//NOT SPONTANEO
        let parteIntera = (<HTMLInputElement>(document.getElementById(this.idTotale))).value;
        let parteDecimale = (<HTMLInputElement>(document.getElementById(this.idTotaleDecimal))).value;
        this.totale = parteIntera + '.' + parteDecimale; // se dececco sottraggo dal totale colonna il totale della riga
        this.importoTotale = this.importoTotale * 1 - this.totale * 1;
        (<HTMLInputElement>document.getElementById(this.idQuantita)).value = ''; // quando dececk risetto la quantità a 0
        (<HTMLInputElement>document.getElementById(this.idTotale)).value = ''; // quando dececk risetto il totale vuoto
        (<HTMLInputElement>document.getElementById(this.idTotaleDecimal)).value = ''; // quando dececk risetto la parte decimale vuota
        (<HTMLInputElement>document.getElementById(this.idQuantita) as HTMLButtonElement).disabled = true;
        this.idSelezionato = '';
        if(this.aliquotaIva>0) {
          this.controlloAliquotaMaggioreZero.emit(false);
          }
      }
      else {//SPONTANEO
        this.controlloSeVuotoPagamentoSpontaneoEE.emit(true);
        this.storageService.setErrorePagamentoSpontaneo("true");
        (<HTMLInputElement>document.getElementById(this.idTotale) as HTMLButtonElement).value = '';
        (<HTMLInputElement>document.getElementById(this.idTotale) as HTMLButtonElement).disabled = true;
        (<HTMLInputElement>document.getElementById(this.idTotaleDecimal) as HTMLButtonElement).value = '';
        (<HTMLInputElement>document.getElementById(this.idTotaleDecimal) as HTMLButtonElement).disabled = true;
        (<HTMLInputElement>document.getElementById(this.idQuantita) as HTMLButtonElement).disabled = true;
        (<HTMLInputElement>document.getElementById('inputTotaleDecimal-' + e.target.value) as HTMLButtonElement).disabled = true; // disabilita i decimali
        (<HTMLInputElement>document.getElementById(this.idQuantita)).value = ''; // va a metter nella casella quantita vuota ma non valorizza quantita
        this.sommaTotaleRiga(e, null);
      }

      // setta il totale della colonna a zero se non è selezionato nessun servizio
      if (listaDett.length.toString() === '0') {
        this.importoTotale = 0;
      }
    }

    // gestisce messaggio di errore se selezionati più di 5 dettagli
    if (listaDett.length > 5) {
      this.alertMaxCinque = true;
      localStorage.setItem('oltreCinque', '1');
      this.controlloCinqueServizi.emit(true);
    }
    else {
      this.alertMaxCinque = false;
      localStorage.setItem('oltreCinque', '0');
      this.controlloCinqueServizi.emit(false);
    }

    // SAVE STORAGE
    this.choicesSelezionati = []; //svuoto l'array
    this.saveStorage(listaDett.value, this.quantita);
    //CONTROLLO DEL TOTALE================================================
    if (this.importoTotale == undefined || this.importoTotale == null || this.importoTotale == "" || this.importoTotale == 0) {
      this.controlloSeVuotoImporto = true;
      this.storageService.setTotale("true");
    } else {
      this.controlloSeVuotoImporto = false;
      this.storageService.setTotale("false");
    }
    this.controlloSeVuotoImportoEE.emit(this.controlloSeVuotoImporto);
    //FINE CONTROLLO DEL TOTALE================================================

  }

  totaleDettaglio: any;
  importoTotale: any = 0;
  convAltnumber: any = 0;
  totString = '';

  sommaTotaleRiga(e, idCausaleParam: any) {
    this.storageService.setErrorePagamentoSpontaneo("true");
    let controlloSeVuotoPagamentoSpontaneo = true;
    this.choices.forEach((element) => {
      if (element.idCausale == idCausaleParam) {
        if(e!=null)
          element.quantita = e.target.value;
        else   element.quantita=1;
      }

      if (element.idCausale == idCausaleParam && !Utility.isStringNullOrUndefinedOrEmpty(element.importo)) {
        let dett = element.quantita;
        localStorage.setItem('quantita' + element.idCausale, dett);
        this.importoTotale = this.calcoloTotale(element.importo, element.aliquota, element.quantita);
        let importoTotaleFix = this.importoTotale;

        if (this.importoTotale.toString().includes('.') || this.importoTotale.toString().includes(',')) {
          (<HTMLInputElement>document.getElementById('inputTotale-' + element.idCausale)).value = this.fParteIntera(importoTotaleFix);
          (<HTMLInputElement>document.getElementById('inputTotaleDecimal-' + element.idCausale)).value = this.fParteDecimale(Number(importoTotaleFix).toFixed(2));
        } else if (this.ritornaStatoPagamentoSpontaneo(e.target.value) == '0') {
          (<HTMLInputElement>document.getElementById('inputTotale-' + element.idCausale)).value = importoTotaleFix;
          (<HTMLInputElement>document.getElementById('inputTotaleDecimal-' + element.idCausale)).value = '00';
        }

      }

      //Entro nell'if solo se si tratta di pagamento spontaneo
      if (this.ritornaStatoPagamentoSpontaneo(element.idCausale) == '1') {

        let parteIntera = (<HTMLInputElement>document.getElementById('inputTotale-' + element.idCausale)).value;
        let parteDecimale = (<HTMLInputElement>document.getElementById('inputTotaleDecimal-' + element.idCausale)).value;
        let lunghezza = parteDecimale.length;

        if (lunghezza == 1) {
          (<HTMLInputElement>document.getElementById('inputTotaleDecimal-' + element.idCausale)).value = parteDecimale + '0';
        }

        if (parteDecimale == '' && parteIntera != '') {
          parteDecimale = '00';
          (<HTMLInputElement>document.getElementById('inputTotaleDecimal-' + element.idCausale)).value = '00';
        }
        if (parteIntera == '' && parteDecimale != '') {
          parteIntera = '0';
          (<HTMLInputElement>document.getElementById('inputTotale-' + element.idCausale)).value = parteIntera;
        }
        this.totaleAltro = parteIntera + '.' + parteDecimale;

        if (this.totaleAltro == '.') {
          this.totaleAltro = '';
        }

        localStorage.setItem('totaleAltro' + element.idCausale, this.totaleAltro);
        element.quantita = '';

        this.totale = (<HTMLInputElement>(document.getElementById("inputTotale-" + element.idCausale))).value;
          this.totaleDecimale = (<HTMLInputElement>(document.getElementById('inputTotaleDecimal-' + element.idCausale))).value;

          //Verifica seil pagamento spontaneo e' checked
          var checkedSpontaneo = (<HTMLInputElement>(document.getElementById('checkbox-' + element.idCausale))).checked;
          if (checkedSpontaneo && (this.totale == "" || this.totale <= 0) && (this.totaleDecimale == "" || this.totaleDecimale <= 0)) {
            this.storageService.setErrorePagamentoSpontaneo("false");//visualizzo errore
            controlloSeVuotoPagamentoSpontaneo = false;
          }

        //invio al padre (anagrafica)
        this.controlloSeVuotoPagamentoSpontaneoEE.emit(controlloSeVuotoPagamentoSpontaneo);
      }
    });//End for
    this.sommaTotaleColonna();
  }

  sommaTotaleColonna() {
    let totNum: number = 0 * 1;
    let i: any = 0;

    while (i < this.choices.length) {
      let stingaId = 'inputTotale-' + this.choices[i].idCausale;
      let stingaId2 = 'inputTotaleDecimal-' + this.choices[i].idCausale;
      let valorePreso = (<HTMLInputElement>document.getElementById(stingaId)).value;
      let valorePresoDecimal = (<HTMLInputElement>(document.getElementById(stingaId2))).value;

      let numeroUnito = valorePreso + '.' + valorePresoDecimal;

      if (numeroUnito == '.') {
        numeroUnito = '0';
      }

      let numeroUnitoNumber: number = +numeroUnito;
      totNum = (numeroUnitoNumber * 1) + (totNum * 1);
      i++;
    }
    this.importoTotale = totNum;

    if (this.importoTotale == undefined || this.importoTotale == null || this.importoTotale == "" || this.importoTotale == 0) {
      this.controlloSeVuotoImporto = true;
      this.storageService.setTotale("true");
    } else {
      this.controlloSeVuotoImporto = false;
      this.storageService.setTotale("false");
    }
    this.controlloSeVuotoImportoEE.emit(this.controlloSeVuotoImporto);
    //FINE CONTROLLO DEL TOTALE================================================
  }


  fParteIntera(importo: any) {
    importo = importo.toString();
    var posizionePunto: number = importo.indexOf('.');
    var importoIntero = importo.substring(0, posizionePunto);
    return importoIntero;
  }

  fParteDecimale(importoParam: any) {
    //console.log("parteDecimal" + importo.toString());
    var temp = importoParam*1;
    var importo = temp.toFixed(2).toString();
    var posizionePunto: number = importo.indexOf('.');
    var importoDecimale = importo.substring(posizionePunto + 1, importo.length);
    //RDA aggiunto zero all fine di cifra decimale
    if (importoDecimale.length == 1) { importoDecimale += "0" }
    return importoDecimale;
  }

  //RDA metodo che si occupa della visualizzazione del totale riga nella nella tabella di riepilogo,
  //serve ad aggiunge lo zero se ai decimali abbiamo slo una cifra decimale
  visualizzaTotaleRiepilogo(totaleRiga: any) {
    if (!totaleRiga.toString().includes(".")) totaleRiga = totaleRiga + ".00";
    // var parteIntera=this.parteIntera(totaleRiga);
    // var parteDecimale=this.parteDecimale(totaleRiga);
    //console.log("visualizzaTotaleRiepilogo  parteIntera " + parteIntera + " parteDecimale "+parteDecimale);
    // return parteIntera+"."+parteDecimale;
    return totaleRiga;
  }
  checkValoreNegativo($event){
    //console.log($event.target.value);
    let idQuantita = $event.target.id;

    this.quantita = $event.target.value;
    if (this.quantita<=0)
    (<HTMLInputElement>document.getElementById(idQuantita)).value='1';
        
  }

}

