<div class="card mb-4" *ngIf="visualizzaGrigliaCausali">

  <div class="card-body" [ngModelGroup]="nameModelGroup">

    <div *ngIf="entiEnabled">
      <div class="d-none form-group" [class.cross-validation-error]="hasError('ente')">
        <label [for]="nameModelGroup + 'Ente'">{{
          "INFO_PAGAMENTO.ENTE" | translate
          }}</label>
        <select hidden placeholder="Ente" class="form-control" [id]="nameModelGroup + 'Ente'" 
          [ngModel]="anagrafica.codiceEnte" (ngModelChange)="onSelectEnte($event)" name="ente" required>
          <option *ngFor="let ente of entiEnabled; let i = index" [value]="ente.codiceEnte">
            {{ ente.codiceEnte }}
          </option>
          <!-- <option *ngFor="let ente of entiEnabled; let i=index" [value]="ente.codiceEnte">{{ente.denominazioneEnte?ente.codiceEnte + " - " + ente.denominazioneEnte:ente.codiceEnte + " - denominazione da fornire"}}</option> -->
          <!-- [selected]="i === 0" -->
        </select>
      </div>
      <div class="row">
        <div class="form-group col-sm-6" [class.cross-validation-error]="hasError('servizio')">
                    <input type="text" class="form-control" [id]="nameModelGroup + 'Ente1'" [(ngModel)]="anagrafica.enteDesc" name="enteDesc" [readonly]="true" />
          
        
        </div>
      </div>
      <!--CAUSALE ATTUAL -->
      <div class="form-row">
        <div class="form-group col-sm-12" [class.cross-validation-error]="hasError('causale')">
          <label [for]="nameModelGroup + 'Causale'">{{ "INFO_PAGAMENTO.CAUSALE" | translate }} *</label>
          <textarea [(ngModel)]="anagrafica.causale" [id]="nameModelGroup + 'Causale'" name="causale"
            class="form-control" maxlength="120" rows="3" ng-pattern="/^@[a-zA-Z0-9]+(?:,\s*@[a-zA-Z0-9]+)*$/" required
            placeholder="{{ 'INFO_PAGAMENTO.PLACEHOLDER' | translate }}"></textarea>
        </div>
      </div>
      <!--FINE CAUSALE ATTUALE -->

      <!--CAUSALE RAGGRUPPAMENTO
      <div class="form-row" *ngIf="mostraCausaRaggruppamento">
        <div
          class="form-group col-sm-12"
          [class.cross-validation-error]="hasError('causaleRaggruppamento')"
        >
          <label [for]="nameModelGroup + 'CausaleRaggruppamento'"
            >{{ "INFO_PAGAMENTO.CAUSALE_RAGGRUPPAMENTO" | translate }} *</label
          >
          <textarea
            [(ngModel)]="anagrafica.causaleRaggruppamento"
            [id]="nameModelGroup + 'causaleRaggruppamento'"
            name="causaleRaggruppamento"
            class="form-control"
            maxlength="120"
            rows="3"
            ng-pattern="/^@[a-zA-Z0-9]+(?:,\s*@[a-zA-Z0-9]+)*$/"
            required
            placeholder="{{ 'INFO_PAGAMENTO.PLACEHOLDER' | translate }}"
          ></textarea>
        </div>
      </div>
      -->
      <!--FINE CAUSALE RAGGRUPPAMENTO -->
      <div class="row">
        <!--   [class.cross-validation-error]="hasError('servizio')"-->
        <div class="form-group col-sm-6" [class.cross-validation-error]="hasError('servizio')">
          <label [for]="nameModelGroup + 'Servizio'">{{ "INFO_PAGAMENTO.SERVIZIO" | translate }} *</label>
          <select [ngModel]="anagrafica.idServizio" (ngModelChange)="onSelectApplicazione($event)" 
            [id]="nameModelGroup + 'Servizio'" placeholder="Elenco dei servizi / Applicazioni dell'ente"
            class="form-control" name="servizio" required (change)="calcolaCheckBoxObbligatoria($event)">
            <option *ngFor="let applicazione of applicazioniEnabled" [value]="applicazione.idApplicazione">
              <!--Internazionalizzazione formato campo it:valore italiano # de:valore tedesco-->
              <div *ngIf="translate.currentLang == 'it'">
                {{
                applicazione.denominazioneApp.split("#")[0].split(":")[1] !=
                null
                ? applicazione.denominazioneApp.split("#")[0].split(":")[1]
                : applicazione.denominazioneApp
                }}
              </div>
              <div *ngIf="translate.currentLang == 'de'">
                {{
                applicazione.denominazioneApp.split("#")[1] != null
                ? applicazione.denominazioneApp.split("#")[1].split(":")[1]
                : applicazione.denominazioneApp
                }}
              </div>
            </option>
          </select>
        </div>
      </div>

      
      <div name="tabellaDettagli" *ngIf="mostraTabella">
        <div class="form-group">
          <div class="table-responsive">
            <table class="table">
              <tr class="wrapper">
                <th class="box-intestazione"></th>
                <th class ="box-intestazione"> {{ 'SERVIZI.CAUSALE_DETTAGLIO' | translate }}</th>
                <th class="box-intestazione" >{{ 'SERVIZI.IMPORTO_NETTO' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.ALIQUOTA_IVA' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.QUANTITA' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.TOTALE' | translate }}</th>
                <!--th class="box-intestazione" style="display:none"></th-->
              </tr>
              
              <div *ngFor="let dettaglio of choices">

                <tr class="wrapper" *ngIf="nascondiRighe">
                  <td class="boxCheckbox">
                    <input *ngIf="checkValore" id="{{ 'checkbox-' + dettaglio.idCausale }}" class="checkBoxClass"
                      type="checkbox" name="checkbox" [value]="dettaglio.idCausale"                       
                      (change)="onCheckboxChange($event)" />
                  </td>
                  <!-- dettaglio-->
                  <td class="box">  
                    <div class="rowIcon">
                      <div class="col-md-11">
                        <input *ngIf="dettaglio.descCausale.length <= 70" id="{{ 'idDettaglio-' + dettaglio.idCausale }}" value="{{ dettaglio.descCausale }}"
                          disabled />

                        <textarea *ngIf="dettaglio.descCausale.length > 70" id="{{ 'idDettaglio-' + dettaglio.idCausale }}" 
                          class="descCausale" 
                          disabled>{{ dettaglio.descCausale }}
                        </textarea>
                      </div>
                      <div class="col-md-1" *ngIf="dettaglio.note != null && dettaglio.note.trim() != ''">
                        <!-- Mostra l'icona solo se la checkbox è selezionata -->
                        <fa-icon [icon]="faInfoCircle" *ngIf="isCheckboxSelected[dettaglio.idCausale]" 
                          ngbTooltip="{{ dettaglio.note }}" [container]="'body'" class="text-warning fa-lg"></fa-icon>
                      </div>
                    </div>                   
                  </td>
                  <!-- importo netto-->
                  <td class="box">
                    <input class="inputNumerico" id="{{ 'idimportoNetto-' + dettaglio.idCausale }}" value="{{ dettaglio.importo | number:'0.2' }}"
                      disabled />
                  </td>
                  <!-- aliquota iva-->
                  <td class="box">
                    <input class="inputNumerico" id="{{ 'idAliquotaIva-' + dettaglio.idCausale }}" value="{{ dettaglio.aliquota }}"
                      disabled />
                  </td>
                  <!-- quantità -->
                  <td class="box">
                    <!-- disabilitare la quantita se pagamento spontaneo = 1 -->
                    <input type="number" id="{{ 'inputQuantita-' + dettaglio.idCausale }}" name="inputQuantita"
                      class="inputQuantita" [value]="dettaglio.quantita"
                      (blur)="sommaTotaleRiga($event, dettaglio.idCausale)" max="9999" min="1" pattern="[1-9]" step="1" onkeypress="return event.charCode >= 48 "
                      onkeydown="if(event.key==='.'|| event.key===','|| event.key==='e'){event.preventDefault();}" (change)="checkValoreNegativo($event)"
                      disabled />
                  </td>
                  <td class="box2">
                    <!-- totale riga -->
                    <!-- per passare i valor al tab successivo aggiunger: value="{{dettaglio.totale}}" ed eliminare:[(ngModel)]="dettaglio.totale" appInfoPagamentoPlusTwo_decimal e mettere il type=text -->
                    <!--appInfoPagamentoPlusTwo_decimal -->
                    <input type="number" id="{{ 'inputTotale-' + dettaglio.idCausale }}" name="inputTotale"
                      class="inputTotaleRiga" placeholder="0" value="{{dettaglio.totale}}"
                      (blur)="sommaTotaleRiga($event, dettaglio.idCausale)"
                      onkeydown="if(event.key===',' || event.key==='e'  || event.key==='.'){event.preventDefault();}"
                      
                      disabled />
                    <div class="punto">.&nbsp;</div>
                    <input type="text" id="{{ 'inputTotaleDecimal-' + dettaglio.idCausale }}" maxlength="2"
                      name="inputTotale" class="inputTotoaleRigaDecimal" placeholder="00" value="{{dettaglio.totale}}"
                      (blur)="sommaTotaleRiga($event, dettaglio.idCausale)"
                      onkeydown="if(event.key===',' || event.key==='e'  || event.key==='.'){event.preventDefault();}"
                      (click)="svuota($event, dettaglio.idCausale)" maxlength="2" 
                      disabled />
                    <!-- con direttiva aggiungere:   type="textbox" [(ngModel)]="dettaglio.totale" appInfoPagamentoPlusTwo_decimal  -->
                    <!-- ed eliminare: value="{{dettaglio.totale}}" -->
                  </td>
                </tr>
                <!--tipo contabilizzazione-->
                <td class="box" style="display:none">

                  {{dettaglio.codiceTipoContabilizzazione}}

                </td>


              </div>

              <div class="wrapper">
                <div class="box-totale"></div>

                <div class="box-totale"></div>
                <div class="box-totale"></div>
                <div class="box-totale"></div>
                <div class="box-totale" id="importo">
                  {{ 'INFO_IMPORTO.IMPORTO' | translate }}:
                </div>

                <div class="box" id="cellaTotale">
                  {{ importoTotale.toFixed(2) }}

                </div>
              </div>

            </table>

            <div class="alert alert-danger" role="alert" *ngIf="alertMaxCinque">
              {{ 'MESSAGE_ERROR.LIMITE_SERVIZI' | translate }}
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="alertDecimali">
              {{ 'MESSAGE_ERROR.VALORE_VALIDO' | translate }}
            </div>
          </div>
        </div>
      </div>



      <!--TABELLA DI RIEPILOGO-->
      <div name="tabellaDettagliRiepilogo" *ngIf="!mostraTabella">
        <div class="form-group">
          <div class="table-responsive">
            <table class="table">
              <tr class="wrapper2">
                <th class="box-intestazione" style="display:none"></th>
                <!--<th class="box-intestazione"></th>-->
                <th class="box-intestazione"> {{ 'SERVIZI.CAUSALE_DETTAGLIO' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.IMPORTO_NETTO' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.ALIQUOTA_IVA' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.QUANTITA' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.TOTALE' | translate }}</th>
                <th class="box-intestazione" style="display:none">Tipo contabilizzazione</th>
              </tr>
              
              <div *ngFor="let dettaglio of choices">

                <tr class="wrapper2" *ngIf="nascondiRighe">
                  <td class="boxCheckbox" style="display:none">
                    <input *ngIf="checkValore" id="{{ 'checkbox-' + dettaglio.idCausale }}" type="checkbox"
                      name="checkbox" [value]="dettaglio.idCausale" (change)="onCheckboxChange($event)" />
                  </td>
                  <!-- dettaglio-->
                  <td class="box">
                    <input id="{{ 'idDettaglio-' + dettaglio.idCausale }}" value="{{ dettaglio.descCausale }}"
                      disabled />
                  </td>
                  <!-- importo netto-->
                  <td class="box">
                    <input class="inputNumerico" id="{{ 'idimportoNetto-' + dettaglio.idCausale }}" value="{{ dettaglio.importo | number:'0.2' }}"
                      disabled />
                  </td>
                  <!-- aliquota iva-->
                  <td class="box">
                    <input class="inputNumerico" id="{{ 'idAliquotaIva-' + dettaglio.idCausale }}" value="{{ dettaglio.aliquota }}"
                      disabled />
                  </td>
                  <!-- quantità -->
                  <td class="box">
                    <!-- disabilitare la quantita se pagamento spontaneo = 1 -->
                    <input type="number" id="{{ 'inputQuantita-' + dettaglio.idCausale }}" name="inputQuantita"
                      class="inputQuantita" [value]="dettaglio.quantita"
                      (blur)="sommaTotaleRiga($event, dettaglio.idCausale)" max="9999" min="1" pattern="[1-9]" step="1"
                      onkeydown="if(event.key==='.'|| event.key===','|| event.key==='e'){event.preventDefault();}"
                      disabled />
                  </td>
                  <td class="box">
                    <!-- totale riga -->
                    <!-- per passare i valor al tab successivo aggiunger: value="{{dettaglio.totale}}" ed eliminare:[(ngModel)]="dettaglio.totale" appInfoPagamentoPlusTwo_decimal e mettere il type=text -->
                    <input type="text" id="{{ 'inputTotale-' + dettaglio.idCausale }}" name="inputTotale"
              						class="inputTotaleRiga" placeholder="0.00" value="{{this.visualizzaTotaleRiepilogo(dettaglio.totale)}}"
                          (blur)="sommaTotaleRiga($event, dettaglio.idCausale)"
                          onkeydown="if(event.key===',' || event.key==='e'){event.preventDefault();}" disabled />

                          <!-- ABA TEST class="inputTotaleRiga" placeholder="0.00" value="{{dettaglio.totale}}" -->


                    <!-- con direttiva aggiungere:   type="textbox" [(ngModel)]="dettaglio.totale" appInfoPagamentoPlusTwo_decimal  -->
                    <!-- ed eliminare: value="{{dettaglio.totale}}" -->
                  </td>
                </tr>
                <!--tipo contabilizzazione-->
                <td class="box" style="display:none">

                  {{dettaglio.codiceTipoContabilizzazione}}

                </td>
              </div>

              <div class="wrapper2">
                <div class="box-totale" style="display:none"></div>

                <div class="box-totale"></div>
                <div class="box-totale"></div>
                <div class="box-totale"></div>
                <div class="box-totale" id="importo">Importo:</div>
                <div class="box" id="cellaTotale">
                  {{ importoTotale.toFixed(2) }}
                </div>
              </div>
            </table>

            <div class="alert alert-danger" role="alert" *ngIf="alertMaxCinque">
              Puoi selezionarne al massimo 5 dettagli.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <!-- <div class="card mb-4" *ngIf="visualizzaGrigliaCausali">

  <div class="card-body" [ngModelGroup]="nameModelGroup">

    <div *ngIf="entiEnabled">
      <div class="d-none form-group" [class.cross-validation-error]="hasError('ente')">
        <label [for]="nameModelGroup + 'Ente'">{{
          "INFO_PAGAMENTO.ENTE" | translate
          }}</label>
        <select hidden placeholder="Ente" class="form-control" [id]="nameModelGroup + 'Ente'"
          [ngModel]="anagrafica.codiceEnte" (ngModelChange)="onSelectEnte($event)" name="ente" required>
          <option *ngFor="let ente of entiEnabled; let i = index" [value]="ente.codiceEnte">
            {{ ente.codiceEnte }}
          </option>
        </select>
      </div>
      <div class="row">
        <div class="form-group col-sm-6" [class.cross-validation-error]="hasError('servizio')">
          <input type="text" class="form-control" [id]="nameModelGroup + 'Ente1'" [(ngModel)]="anagrafica.enteDesc"
            name="enteDesc" [readonly]="true" />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-sm-12" [class.cross-validation-error]="hasError('causale')">
          <label [for]="nameModelGroup + 'Causale'">{{ "INFO_PAGAMENTO.CAUSALE" | translate }} *</label>
          <textarea [(ngModel)]="anagrafica.causale" [id]="nameModelGroup + 'Causale'" name="causale"
            class="form-control" maxlength="120" rows="3" ng-pattern="/^@[a-zA-Z0-9]+(?:,\s*@[a-zA-Z0-9]+)*$/" required
            placeholder="{{ 'INFO_PAGAMENTO.PLACEHOLDER' | translate }}"></textarea>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-6" [class.cross-validation-error]="hasError('servizio')">
          <label [for]="nameModelGroup + 'Servizio'">{{ "INFO_PAGAMENTO.SERVIZIO" | translate }} *</label>
          <select [ngModel]="anagrafica.idServizio" (ngModelChange)="onSelectApplicazione($event)"
            [id]="nameModelGroup + 'Servizio'" placeholder="Elenco dei servizi / Applicazioni dell'ente"
            class="form-control" name="servizio" required>
            <option *ngFor="let applicazione of applicazioniEnabled" [value]="applicazione.idApplicazione">
              <div *ngIf="translate.currentLang == 'it'">
                {{
                applicazione.denominazioneApp.split("#")[0].split(":")[1] !=
                null
                ? applicazione.denominazioneApp.split("#")[0].split(":")[1]
                : applicazione.denominazioneApp
                }}
              </div>
              <div *ngIf="translate.currentLang == 'de'">
                {{
                applicazione.denominazioneApp.split("#")[1] != null
                ? applicazione.denominazioneApp.split("#")[1].split(":")[1]
                : applicazione.denominazioneApp
                }}
              </div>
            </option>
          </select>
        </div>
      </div>


      <div name="tabellaDettagli" *ngIf="mostraTabella">
        <div class="form-group">
          <div class="table-responsive">
            <table class="table">
              <tr class="wrapper">
                <th class="box-intestazione">n°</th>
                <th class="box-intestazione"> {{ 'SERVIZI.CAUSALE_DETTAGLIO' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.IMPORTO_NETTO' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.ALIQUOTA_IVA' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.QUANTITA' | translate }}</th>
                <th class="box-intestazione">{{ 'SERVIZI.TOTALE' | translate }}</th>
                <th class="box-intestazione" style="display:none"></th>
              </tr>

              <div *ngFor="let dettaglio of choices">

                <tr class="wrapper" *ngIf="nascondiRighe">
                  <td class="box">
                    <input *ngIf="checkValore" id="{{ 'checkbox-' + dettaglio.id }}" class="checkBoxClass"
                      type="checkbox" name="checkbox" [value]="dettaglio.id" (change)="onCheckboxChange($event)" />
                  </td>
                  <td class="box">
                    <input id="{{ 'idDettaglio-' + dettaglio.id }}" value="{{ dettaglio.descCausale }}" disabled />
                  </td>
                  <td class="box">
                    <input id="{{ 'idimportoNetto-' + dettaglio.id }}" value="{{ dettaglio.importo }}" disabled />
                  </td>
                  <td class="box">
                    <input id="{{ 'idAliquotaIva-' + dettaglio.id }}" value="{{ dettaglio.aliquota }}" disabled />
                  </td>
                  <td class="box">
                    <input type="number" id="{{ 'inputQuantita-' + dettaglio.id }}" name="inputQuantita"
                      class="inputQuantita" [value]="dettaglio.quantita" (blur)="sommaTotaleRiga($event, dettaglio.id)"
                      max="9999" min="1" pattern="[1-9]" step="1"
                      onkeydown="if(event.key==='.'|| event.key===','|| event.key==='e'){event.preventDefault();}"
                      disabled />
                  </td>
                  <td class="box2">
                    <input type="number" id="{{ 'inputTotale-' + dettaglio.id }}" name="inputTotale"
                      class="inputTotaleRiga" placeholder="0" value="{{dettaglio.totale}}"
                      (blur)="sommaTotaleRiga($event, dettaglio.id)"
                      onkeydown="if(event.key===',' || event.key==='e'  || event.key==='.'){event.preventDefault();}"
                      appInfoPagamentoPlusTwo_decimal disabled />
                    <div class="punto">.&nbsp;</div>
                    <input type="text" id="{{ 'inputTotaleDecimal-' + dettaglio.id }}" maxlength="2" name="inputTotale"
                      class="inputTotoaleRigaDecimal" placeholder="00" value="{{dettaglio.totale}}"
                      (blur)="sommaTotaleRiga($event, dettaglio.id)"
                      onkeydown="if(event.key===',' || event.key==='e'  || event.key==='.'){event.preventDefault();}"
                      (click)="svuota($event, dettaglio.id)" maxlength="2" appInfoPagamentoPlusTwo_decimal disabled />
                  </td>
                </tr>
                <td class="box" style="display:none">
                  {{dettaglio.codiceTipoContabilizzazione}}
                </td>
              </div>

              <div class="wrapper">
                <div class="box-totale"></div>

                <div class="box-totale"></div>
                <div class="box-totale"></div>
                <div class="box-totale"></div>
                <div class="box-totale" id="importo">
                  {{ 'INFO_IMPORTO.IMPORTO' | translate }}:
                </div>

                <div class="box" id="cellaTotale">
                  {{ importoTotale.toFixed(2) }}

                </div>
              </div>

            </table>

            <div class="alert alert-danger" role="alert" *ngIf="alertMaxCinque">
              {{ 'MESSAGE_ERROR.LIMITE_SERVIZI' | translate }}
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="alertDecimali">
              {{ 'MESSAGE_ERROR.VALORE_VALIDO' | translate }}
            </div>
          </div>
        </div>
      </div>
      <div name="tabellaDettagliRiepilogo" *ngIf="!mostraTabella">
        <div class="form-group">
          <div class="table-responsive">
            <table class="table">
              <tr class="wrapper2">
                <th class="box-intestazione" style="display:none">n°</th>
                <th class="box-intestazione">CausaleDettaglio</th>
                <th class="box-intestazione">Importo netto</th>
                <th class="box-intestazione">Aliquota iva</th>
                <th class="box-intestazione">Quantità</th>
                <th class="box-intestazione">Totale</th>
                <th class="box-intestazione" style="display:none">Tipo contabilizzazione</th>
              </tr>

              <div *ngFor="let dettaglio of choices">

                <tr class="wrapper2" *ngIf="nascondiRighe">
                  <td class="box" style="display:none">
                    <input *ngIf="checkValore" id="{{ 'checkbox-' + dettaglio.id }}" type="checkbox" name="checkbox"
                      [value]="dettaglio.id" (change)="onCheckboxChange($event)" />
                  </td>
                  <td class="box">
                    <input id="{{ 'idDettaglio-' + dettaglio.id }}" value="{{ dettaglio.descCausale }}" disabled />
                  </td>
                  <td class="box">
                    <input id="{{ 'idimportoNetto-' + dettaglio.id }}" value="{{ dettaglio.importoNetto }}" disabled />
                  </td>
                  <td class="box">
                    <input id="{{ 'idAliquotaIva-' + dettaglio.id }}" value="{{ dettaglio.aliquotaIva }}" disabled />
                  </td>
                  <td class="box">
                    <input type="number" id="{{ 'inputQuantita-' + dettaglio.id }}" name="inputQuantita"
                      class="inputQuantita" [value]="dettaglio.quantita" (blur)="sommaTotaleRiga($event, dettaglio.id)"
                      max="9999" min="1" pattern="[1-9]" step="1"
                      onkeydown="if(event.key==='.'|| event.key===','|| event.key==='e'){event.preventDefault();}"
                      disabled />
                  </td>
                  <td class="box">
                     <input type="text" id="{{ 'inputTotale-' + dettaglio.id }}" name="inputTotale"
                      class="inputTotaleRiga" placeholder="0.00" value="{{dettaglio.totale}}"
                      (blur)="sommaTotaleRiga($event, dettaglio.id)"
                      onkeydown="if(event.key===',' || event.key==='e'){event.preventDefault();}" disabled />
                    </td>
                </tr>
                  <td class="box" style="display:none">

                  {{dettaglio.codiceTipoContabilizzazione}}

                </td>
              </div>

              <div class="wrapper2">
                <div class="box-totale" style="display:none"></div>

                <div class="box-totale"></div>
                <div class="box-totale"></div>
                <div class="box-totale"></div>
                <div class="box-totale" id="importo">Importo:</div>
                <div class="box" id="cellaTotale">
                  {{ importoTotale.toFixed(2) }}
                </div>
              </div>
            </table>

            <div class="alert alert-danger" role="alert" *ngIf="alertMaxCinque">
              Puoi selezionarne al massimo 5 dettagli.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
