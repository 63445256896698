export class Ente {
  codiceEnte: string;
  denominazioneEnte: string;
  cssFile: string;
  elencoApplicazioni: Applicazione[];
  imglogo: string;
  //TEST: ABA aggiunto abilitatoSipaPlus - deve tornare da SIPA-MOPA
  abilitatoSipaPlus: any;
}

export class Applicazione {
  cdp: string;
  cpk: string;
  denominazioneApp: string;
  idApplicazione: number;
  tipoContabilizzazione: string;
  listaCausali: CausaleDto[];
  // userid: string;
  password: string;
}

export class CausaleDto {
  idCausale: string;
  descCausale: string;  //causale
  importo: string;      //importo
  aliquota: string;     //aliquotaIva
  quantita: string;     //quantitaItem
  totale: string;       //importoTotale - importo compreso di aliquota e moltiplicato per quantità
  totaleFix:string;
  fSpontaneo: string;   //pagamentoSpontaneo
  codiceTipoContabilizzazione: string;

  //aggiunto per compatibilità
  codiceEnte: string;
  causaleRaggruppamento: string;
  importoImposta: string;       //sempre 0??
  importoUnitario: number;      //uguale ad importo

  fObbligatorio: number;   //flag obbligatorio
}
